/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useDataLayerValue} from '../../../../DataLayer'

export function AsideMenuMain() {
  const intl = useIntl()
  const [{user}, dispatch] = useDataLayerValue()

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />*/}
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Operation</span>
          </div>
        </div>
      </>
      <AsideMenuItemWithSub
        to='#'
        title='Alert Management'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/alerts/open' title='View All Alerts' hasBullet={true} />
        {/* <AsideMenuItem to='/all-groups' title='View Layout Groups' hasBullet={true} />*/}
      </AsideMenuItemWithSub>
      <>
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Layouts</span>
          </div>
        </div>
      </>
      {user?.menu.map((item, index) => (
        <AsideMenuItemWithSub
          to={item.url}
          title={item.name}
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to={item.url} title='Chains' hasBullet={false} />
          {item?.subMenu.map((subsector, index2) => (
            <>
              {subsector.subMenu.length == 0 && (
                <>
                  {window.location.hostname.toLowerCase() === 'e2eq.lk' && (
                    <>
                      <AsideMenuItem to={subsector.url} title={subsector.name} hasBullet={true} />
                    </>
                  )}

                  {window.location.hostname.toLowerCase() === 'portal.smartgas.biz' && (
                    <>
                      <AsideMenuItem
                        to={subsector.url.replace('facility-detail', 'facility-detail2')}
                        title={subsector.name}
                        hasBullet={true}
                      />
                    </>
                  )}

                  {window.location.hostname.toLowerCase() !== 'portal.smartgas.biz' &&
                    window.location.hostname.toLowerCase() !== 'e2eq.lk' && (
                      <>
                        <AsideMenuItem
                          to={subsector.url.replace('facility-detail', 'facility-detail2')}
                          title={subsector.name}
                          hasBullet={true}
                        />
                      </>
                    )}
                </>
              )}

              {subsector.subMenu.length > 0 && (
                <AsideMenuItemWithSub
                  to={subsector.url}
                  title={subsector.name}
                  icon='/media/icons/duotune/finance/fin006.svg'
                  fontIcon='bi-layers'
                >
                  {subsector.subMenu.map((factory, index3) => (
                    <>
                      {/* need to fix */}

                      {window.location.hostname.toLowerCase() === 'portal.smartgas.biz' && (
                        <>
                          {factory.subMenu.length == 0 && (
                            <AsideMenuItem
                              to={factory.url.replace('facility-detail', 'facility-detail2')}
                              title={factory.name}
                              hasBullet={true}
                            />
                          )}
                        </>
                      )}
                      {window.location.hostname.toLowerCase() != 'portal.smartgas.biz' && (
                        <>
                          {factory.subMenu.length == 0 && (
                            <AsideMenuItem to={factory.url} title={factory.name} hasBullet={true} />
                          )}
                        </>
                      )}

                      {factory.subMenu.length > 0 && (
                        <AsideMenuItemWithSub
                          to={factory.url}
                          title={factory.name}
                          icon='/media/icons/duotune/finance/fin006.svg'
                          fontIcon='bi-layers'
                        >
                          {factory.subMenu.map((itm1, index3) => (
                            <AsideMenuItem to={itm1.url} title={itm1.name} hasBullet={true} />
                          ))}
                        </AsideMenuItemWithSub>
                      )}
                    </>
                  ))}
                </AsideMenuItemWithSub>
              )}
            </>
          ))}
        </AsideMenuItemWithSub>
      ))}

      {user.role == 'Administrator' && (
        <>
          <>
            <div className='menu-item'>
              <div className='menu-content pt-8 pb-2'>
                <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
              </div>
            </div>

            <AsideMenuItemWithSub
              to='#'
              title='Layout Management'
              icon='/media/icons/duotune/general/gen025.svg'
              fontIcon='bi-layers'
            >
              <AsideMenuItem to='/all-layouts' title='All Sensor Layouts' hasBullet={true} />
              {/* <AsideMenuItem to='/all-groups' title='View Layout Groups' hasBullet={true} />*/}
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
              to='#'
              title='360 Views'
              icon='/media/icons/duotune/general/gen025.svg'
              fontIcon='bi-layers'
            >
              <AsideMenuItem to='/map/elephanthouse' title='Views' hasBullet={true} />
              {/* <AsideMenuItem to='/all-groups' title='View Layout Groups' hasBullet={true} />*/}
            </AsideMenuItemWithSub>
          </>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Master</span>
            </div>
          </div>
          <AsideMenuItem
            to='/object-browser'
            icon='/media/icons/duotune/general/gen002.svg'
            title='Object Browser'
            fontIcon='bi-app-indicator'
          />
        </>
      )}
    </>
  )
}
